<template>
  <div>
    <div class="all">
      <!-- 导航 -->
      <van-nav-bar title="退货退款" left-arrow @click-left="onClickLeft" />
      <!-- 商品 -->
      <div class="item">
        <div class="_item">
          <p class="title">售后商品</p>
          <div class="item-info">
            <!-- refundInfo[0].imgUrl -->
            <img v-if="refundInfo" style="width: 80px; height: 80px" :src="this.$route.query.img" alt />
            <div class="desc-spec">
              <div class="desc">
                <span style="display: inline-block; width: 180px">{{
                  refundInfo[0].spuName.substr(0, 20) + "..."
                }}</span>
                <span>￥{{ refundInfo[0].paymentPrice }}</span>
              </div>
              <div class="spec">
                <span>规格：{{ refundInfo[0].specInfo == null || refundInfo[0].specInfo == 'null' ? '统一规格' :
                  refundInfo[0].specInfo }}</span>
                <span>*{{ refundInfo[0].quantity }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <p class="choose-serve">选择服务类型</p> -->
      <!-- 选择服务 -->
      <div class="choose-item">
        <div class="only-return" @click="backReason">
          <span class="only">退货原因</span>
          <span v-if="chooseReason" style="color: #333333">{{
            chooseReason
          }}</span>
          <span v-else>请选择</span>
          <van-icon name="arrow" size="18" />
        </div>
        <div class="much-money">
          <p>退货金额</p>
          <div class="edit-price">
            <span v-if="isReset === false">{{ price }}</span>
            <van-field v-else v-model="price" name="用户名" placeholder="请输入退款金额" style="width: 200px" />
            <span style="color: #999999" @click="resetPrice">{{
              resetWord
            }}</span>
          </div>
          <p style="color: #999999">
            可修改，最多￥{{ this.refundInfo[0].paymentPrice }}，含包邮￥0.00
          </p>
        </div>
        <div class="add-desc">
          <p class="_desc">补充描述和凭证</p>
          <div class="desc-content">
            <van-field style="background-color: #f7f7f7" v-model="message" rows="2" autosize type="textarea"
              maxlength="200" placeholder="补充描述，有助于商家更好处理售后问题" show-word-limit />
            <!-- 上传文件 -->
            <van-uploader style="margin-bottom: 15px" v-model="fileList" multiple :after-read="uploadImg(fileList)"
              :before-delete="deleteImg" :max-count="6">
              <div class="do-it-self">
                <van-icon color="#999999" name="video-o" size="25" />
                <span>上传凭证</span>
                <span>(最多六张)</span>
              </div>
            </van-uploader>
          </div>
        </div>
      </div>
      <!-- 最下面按钮 -->
      <div class="last-btn">
        <van-button type="primary" round color="#E54320 " block @click="lastBtn">提交</van-button>
      </div>
      <!-- 退货原因 -->
      <van-action-sheet :closeable="false" v-model="showBackReason" title="退货原因">
        <div class="content">
          <div class="content-reason" @click="clickReason(item, index)" v-for="(item, index) in allReason" :key="index">
            <van-icon :color="num == index ? '#E54320' : ''" name="checked" size="16" />
            <span>{{ item.reasonWord }}</span>
          </div>
        </div>
        <div class="commit-btn">
          <van-button type="primary" round color="#E54320 " block @click="commitReason">提交</van-button>
        </div>
      </van-action-sheet>
    </div>
  </div>
</template>

<script>
import { upload } from '@/api/tsMallorderDetail'
import { createRefundItem, updateOrderrefunds } from "@/api/afterSales";
export default {
  data() {
    return {
      show: false,
      message: "",
      // 上传文件
      fileList: [],
      // 修改价格
      price: "",
      isReset: false,
      resetWord: "修改",
      //  退货原因
      showBackReason: false,
      allReason: [
        {
          reasonWord: "7天无理由退货",
        },
        {
          reasonWord: "大小/尺寸与描述不符",
        },
        {
          reasonWord: "颜色/图案/款式与描述不符",
        },
        {
          reasonWord: "材料面料/功能效果与描述不符",
        },
        {
          reasonWord: "卖家发错货",
        },
        {
          reasonWord: "商品破损/包装破损",
        },
        {
          reasonWord: "假冒品牌",
        },
        {
          reasonWord: "其他",
        },
      ],
      chooseReason: null, //退货原因
      num: -1, //退货原因索引写活一个
      color: "", //颜色
      isReason: "", //请选择显示的是不是退货原因
      refundInfo: {},
    };
  },
  activated() { },
  created() {
    console.log(this.$route, '创建退货退款')
    this.$store.commit("setSession", this.$route.query.session)
    this.refundInfo = JSON.parse(localStorage.getItem("refundInfo"));
    this.price = this.refundInfo[0].paymentPrice;
  },
  methods: {
    //弹出退货原因
    backReason() {
      this.showBackReason = true;
    },
    //点击退货原因
    clickReason(item, index) {
      this.num = index;
      this.chooseReason = item.reasonWord;
    },
    // 提交退货原因
    commitReason() {
      this.showBackReason = false;
    },
    // 修改价格
    resetPrice() {
      if (this.price > this.refundInfo[0].paymentPrice) {
        return this.$Toast("退款金额不应大于支付金额");
      }
      this.isReset = !this.isReset;
      if (!this.isReset) {
        this.resetWord = "修改";
      } else {
        this.resetWord = "保存";
      }
    },
    // 最下面提交按钮
    lastBtn() {
      if (this.$route.query.update == 'update') {
        this.updateApply()
      } else {
        this.createApply()
      }
    },

    createApply() {
      let picArr = [];
      picArr = this.fileList.map((item) => {
        return item.content;
        // return item.file.name;
      });
      let params = {
        orderId: this.refundInfo[0].orderId,
        orderType: localStorage.getItem("orderType"),
        refundReson: this.chooseReason,
        refundAmount: this.price,
        description: this.message,
        pictures: picArr.join(","),
        status: '2'
      };
      if (!this.chooseReason) {
        return this.$Toast("请选择退货原因");
      }
      if (!this.message) {
        return this.$Toast("请填写描述");
      }
      if (!picArr.length) {
        return this.$Toast("请上传凭证");
      }
      createRefundItem(params).then((res) => {
        if (res.data.code === 0) {
          // this.$Toast("创建退货退款成功");
          setTimeout(this.closePage, 1000);
        }
      });
    },

    updateApply() {
      let picArr = [];
      picArr = this.fileList.map((item) => {
        return item.content;
        // return item.file.name;
      });
      let params = {
        orderId: this.refundInfo[0].orderId,
        id: this.$route.query.returnId,
        orderType: localStorage.getItem("orderType"),
        refundReson: this.chooseReason,
        refundAmount: this.price,
        description: this.message,
        pictures: picArr.join(","),
        status: '2'
      };
      if (!this.chooseReason) {
        return this.$Toast("请选择退货原因");
      }
      if (!this.message) {
        return this.$Toast("请填写描述");
      }
      if (!picArr.length) {
        return this.$Toast("请上传凭证");
      }
      updateOrderrefunds(params).then((res) => {
        if (res.data.code === 0) {
          this.$Toast("退货退款修改成功");
          this.$router.replace({
            path: '/returnMoneyDetail',
            query: {
              id: this.$route.query.returnId,
              session: this.$route.query.session
            }
          })
        }
      })
    },

    //提交完成后退出页面
    closePage() {
      // 提交退货退款去的页面
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
      let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isIOS) {
        // 退货退款方法
        window.webkit.messageHandlers.close.postMessage("up");
      }
      if (isAndroid) {
        // 退货退款方法
        window.android.close();
      }
    },
    // 图片上传成功
    uploadImg() {
      return (file) => {
        file.status = 'uploading'
        file.message = '上传中'
        let formData = new window.FormData()
        formData.append('file', file.file)
        formData.append('fileType', 'imgage/png'),
          formData.append('dir', 'material')
        upload(formData).then(res => {
          if (res.data.code === 0) {
            file.status = ''
            file.message = ''
            file.content = res.data.data
            // this.fileList.push({
            //   content: res.data.data
            // })
            // this.scoreList.push({
            //   picUrls: res.data.data,
            //   index: index,
            //   name: file.file.name,
            //   id: nanoId
            // })
          }
        }).catch(() => {
          file.status = 'failed',
            file.message = '上传失败'
        })
      }
    },
    // 删除图片
    deleteImg(item, info) {
      this.fileList.splice(info.index, 1);
    },
    // 返回上一页
    onClickLeft() {
      if (this.$route.query.first == 0) {
        this.$router.back(-1)
      } else {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android
        let isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        if (isIOS) {
          window.webkit.messageHandlers.close.postMessage("up");
        }
        if (isAndroid) {
          window.android.close();
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.all {
  width: 100%;
  height: 100vh;
  background-color: #f1f1f1;
  margin-top: 60px;
}

/deep/ .van-nav-bar {
  position: fixed;
  height: 46px;
  width: 100%;
  top: 0;
}

.item {
  background-color: #fff;
  width: 100%;
  height: 158px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;

  ._item {
    .title {
      font-size: 17px;
    }

    .item-info {
      margin-top: 10px;
      display: flex;

      .desc-spec {
        margin-left: 10px;
        flex: 1;

        .desc {
          display: flex;
          justify-content: space-between;
        }

        .spec {
          margin-top: 10px;
          color: #999999;
          font-family: PingFang-SC-Regular;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

.choose-serve {
  font-size: 16px;
  padding: 15px;
  color: #999999;
}

.choose-item {
  background-color: #fff;

  .only-return {
    box-sizing: border-box;
    padding: 15px;
    border-bottom: 1px solid #f1f1f1;
    color: #999999;

    .only {
      flex: 1;
      color: #333333;
    }

    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .much-money {
    padding: 15px;
    border-bottom: 1px solid #f1f1f1;

    .edit-price {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }
  }

  .add-desc {
    padding: 15px;

    ._desc {
      margin-bottom: 8px;
    }

    .desc-content {
      width: 100%;
      // height: 200px;
      background-color: #f7f7f7;
      border-radius: 10px;

      .van-uploader {
        .do-it-self {
          width: 78px;
          height: 78px;
          border: 1px dashed #999999;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          color: #999999;
        }
      }
    }
  }
}

.last-btn {
  margin: 15px 15px;
}

.content {
  margin: 0 15px 15px;

  .content-reason {
    padding: 13px 0;
    border-bottom: 1px solid #e8e8e8;
    display: flex;
    align-items: center;

    .van-icon {
      margin-right: 8px;
    }
  }
}

.commit-btn {
  margin: 15px 15px;
}

/deep/ .van-uploader__wrapper:nth-child(1) {
  margin-left: 18px;
}

/deep/ .van-uploader__preview {
  margin: 5px 12px;
}
</style>